export enum EntryTypeEnum {
  wplh = 'wplh',
  piechart = 'piechart',
  scurve = 'scurve',
  ikigai = 'ikigai',
  journal = 'journal',
  resume = 'resume',
}

export type EntryType = keyof typeof EntryTypeEnum;
