import {
  call,
  put,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import updateWPLH from '../../api/journal/wplh/updateWPLH';
import createWPLH from '../../api/journal/wplh/createWPLH';

import {
  selectWPLH,
  setWPLHIsUpdating,
  setWPLH,
  wplhCreateNewItem,
  wplhLoadExistingItem,
  wplhUpdateLevels,
  wplhUpdateReflection,
  wplhSubmit,
} from '../slices/wplhSlice';

import { WPLHDashboard } from '../../types/journalItems/WPLHDashboard';
import {
  ActivityIdPayload,
  Payload,
  ReflectionPayload,
  WPLHLevelsPayload,
} from '../types/payloads';
import { User } from '../../types/User';
import { selectUser } from '../slices/userSlice';
import { JournalItemEnum } from '../../types/JournalItem';
import getWPLHDashboard from '../../api/journal/wplh/getWPLH';

const DEBOUNCE_WAIT_TIME = 500;

function* updateWPLHDebounced() {
  const user: User = yield select(selectUser);

  if (user == null) {
    return;
  }
  yield delay(DEBOUNCE_WAIT_TIME);

  const currentWPLH: WPLHDashboard | null = yield select(selectWPLH);

  if (currentWPLH == null) return;

  yield put(setWPLHIsUpdating({ isUpdating: true }));
  yield call(updateWPLH, currentWPLH);
  yield put(setWPLHIsUpdating({ isUpdating: false }));
}

function* createWPLHItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;
  const user: User | null = yield select(selectUser);

  const newWPLH = {
    user: user?.uid ?? '',
    id: activityId,
    workLevel: 0,
    playLevel: 0,
    loveLevel: 0,
    healthLevel: 0,
    createdAt: Date.now(),
    reflection: '',
  };

  yield put(setWPLHIsUpdating({ isUpdating: true }));

  if (user != null) {
    yield call(createWPLH, newWPLH);
  }

  yield put(
    setWPLH({
      wplh: {
        ...newWPLH,
        title: 'WPLH',
        type: JournalItemEnum.activity,
        updatedAt: Date.now(),
        isSubmitted: false,
      },
    }),
  );
  yield put(setWPLHIsUpdating({ isUpdating: false }));
}

function* loadWPLHItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;

  yield put(setWPLHIsUpdating({ isUpdating: true }));

  const loadedWPLH: WPLHDashboard | null = yield call(
    getWPLHDashboard,
    activityId,
  );
  yield put(
    setWPLH({
      wplh: loadedWPLH,
    }),
  );

  yield put(setWPLHIsUpdating({ isUpdating: false }));
}

function* updateWPLHLevels({ payload }: Payload<WPLHLevelsPayload>) {
  const currentWPLH: WPLHDashboard | null = yield select(selectWPLH);
  if (currentWPLH == null) return;

  yield put(
    setWPLH({
      wplh: {
        ...currentWPLH,
        ...payload,
      },
    }),
  );
}

function* updateWPLHReflection({ payload }: Payload<ReflectionPayload>) {
  const currentWPLH: WPLHDashboard | null = yield select(selectWPLH);
  if (currentWPLH == null) return;

  yield put(
    setWPLH({
      wplh: {
        ...currentWPLH,
        ...payload,
      },
    }),
  );
}

function* submitWPLH() {
  const currentWPLH: WPLHDashboard | null = yield select(selectWPLH);
  if (currentWPLH == null) return;
  yield put(setWPLHIsUpdating({ isUpdating: true }));

  yield call(updateWPLH, {
    ...currentWPLH,
    isSubmitted: true,
  });

  yield put(
    setWPLH({
      wplh: {
        ...currentWPLH,
        isSubmitted: true,
      },
    }),
  );
  yield put(setWPLHIsUpdating({ isUpdating: false }));
}

function* WPLHSaga() {
  yield takeLatest(setWPLH, updateWPLHDebounced);
  yield takeEvery(wplhCreateNewItem, createWPLHItem);
  yield takeEvery(wplhLoadExistingItem, loadWPLHItem);
  yield takeEvery(wplhUpdateLevels, updateWPLHLevels);
  yield takeEvery(wplhUpdateReflection, updateWPLHReflection);
  yield takeEvery(wplhSubmit, submitWPLH);
}

export default WPLHSaga;
