import {
  call,
  put,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import updatePieChart from '../../api/journal/pieChart/updatePieChart';

import {
  selectPieChart,
  setPieChartIsUpdating,
  setPieChart,
  pieChartCreateNewItem,
  pieChartLoadExistingItem,
  pieChartUpdateReflection,
  pieChartSubmit,
  pieChartUpdateCurrentActivities,
  pieChartUpdateIdealActivities,
} from '../slices/pieChartSlice';

import { PieChart, PieChartActivity } from '../../types/journalItems/PieChart';
import { JournalItemEnum } from '../../types/JournalItem';
import createPieChart from '../../api/journal/pieChart/createPieChart';
import { selectUser } from '../slices/userSlice';
import { User } from '../../types/User';
import {
  ActivityIdPayload,
  Payload,
  PieChartActivitiesPayload,
  ReflectionPayload,
} from '../types/payloads';
import getPieChart from '../../api/journal/pieChart/getPieChart';

const DEBOUNCE_WAIT_TIME = 500;

function* updatePieChartDebounced() {
  const user: User | null = yield select(selectUser);
  if (user == null) {
    return;
  }
  yield delay(DEBOUNCE_WAIT_TIME);

  const currentPieChart: PieChart | null = yield select(selectPieChart);

  if (currentPieChart == null) return;

  yield put(setPieChartIsUpdating({ isUpdating: true }));
  yield call(updatePieChart, currentPieChart);
  yield put(setPieChartIsUpdating({ isUpdating: false }));
}

function* createPieChartItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;

  const user: User | null = yield select(selectUser);

  const newPieChart: PieChart = {
    id: activityId,
    user: user?.uid ?? '',
    createdAt: Date.now(),
    currentActivities: new Array<PieChartActivity>(),
    idealActivities: new Array<PieChartActivity>(),
    reflection: '',
    title: 'piechart',
    type: JournalItemEnum.activity,
    isSubmitted: false,
    updatedAt: Date.now(),
  };

  yield put(setPieChartIsUpdating({ isUpdating: true }));

  if (user != null) {
    const {
      user: uid,
      id,
      currentActivities,
      idealActivities,
      createdAt,
    } = newPieChart;
    yield call(createPieChart, {
      uid,
      id,
      currentActivities,
      idealActivities,
      createdAt,
    });
  }

  yield put(
    setPieChart({
      pieChart: newPieChart,
    }),
  );
  yield put(setPieChartIsUpdating({ isUpdating: false }));
}

function* loadPieChartItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;
  yield put(setPieChartIsUpdating({ isUpdating: true }));

  const loadedPieChart: PieChart | null = yield call(getPieChart, activityId);
  yield put(
    setPieChart({
      pieChart: loadedPieChart,
    }),
  );
  yield put(setPieChartIsUpdating({ isUpdating: false }));
}

function* updatePieChartCurrentActivities({
  payload,
}: Payload<PieChartActivitiesPayload>) {
  const currentPieChart: PieChart | null = yield select(selectPieChart);
  if (currentPieChart == null) return;

  const { activities } = payload;
  yield put(
    setPieChart({
      pieChart: {
        ...currentPieChart,
        currentActivities: activities,
      },
    }),
  );
}

function* updatePieChartIdealActivities({
  payload,
}: Payload<PieChartActivitiesPayload>) {
  const currentPieChart: PieChart | null = yield select(selectPieChart);
  if (currentPieChart == null) return;

  const { activities } = payload;
  yield put(
    setPieChart({
      pieChart: {
        ...currentPieChart,
        idealActivities: activities,
      },
    }),
  );
}

function* updatePieChartReflection({ payload }: Payload<ReflectionPayload>) {
  const currentPieChart: PieChart | null = yield select(selectPieChart);
  if (currentPieChart == null) return;

  yield put(
    setPieChart({
      pieChart: {
        ...currentPieChart,
        ...payload,
      },
    }),
  );
}

function* submitPieChart() {
  const currentPieChart: PieChart | null = yield select(selectPieChart);
  if (currentPieChart == null) return;
  yield put(setPieChartIsUpdating({ isUpdating: true }));

  yield call(updatePieChart, { ...currentPieChart, isSubmitted: true });

  yield put(
    setPieChart({
      pieChart: { ...currentPieChart, isSubmitted: true },
    }),
  );
  yield put(setPieChartIsUpdating({ isUpdating: false }));
}

function* pieChartSaga() {
  yield takeLatest(setPieChart, updatePieChartDebounced);
  yield takeEvery(pieChartCreateNewItem, createPieChartItem);
  yield takeEvery(pieChartLoadExistingItem, loadPieChartItem);
  yield takeEvery(
    pieChartUpdateCurrentActivities,
    updatePieChartCurrentActivities,
  );
  yield takeEvery(pieChartUpdateIdealActivities, updatePieChartIdealActivities);
  yield takeEvery(pieChartUpdateReflection, updatePieChartReflection);
  yield takeEvery(pieChartSubmit, submitPieChart);
}

export default pieChartSaga;
