import firebase from 'gatsby-plugin-firebase';
import { JournalEntry } from '../../types/journalItems/JournalEntry';

const updateJournalEntry = async (journalEntry: JournalEntry) => {
  await firebase
    .database()
    .ref(`journalentries/${journalEntry.id}`)
    .update({
      ...journalEntry,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
};

export default updateJournalEntry;
