import firebase from 'gatsby-plugin-firebase';
import { SCurve } from '../../../types/journalItems/SCurve';

const getSCurve = async (id: string): Promise<SCurve | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`scurve/${id}`)
      .once('value');
    const sCurve: SCurve = snapshot.val();
    return sCurve;
  } catch (error) {
    return null;
  }
};

export default getSCurve;
