import { SCurve } from '../../types/journalItems/SCurve';

export type sCurveState = {
  isUpdating: boolean;
  sCurve: SCurve | null;
};

export const INITIAL_SCURVE_STATE = {
  isUpdating: false,
  sCurve: null,
} as sCurveState;
