import firebase from 'gatsby-plugin-firebase';
import { PieChart } from '../../../types/journalItems/PieChart';

const getPieChart = async (id: string): Promise<PieChart | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`piechart/${id}`)
      .once('value');
    const pieChart: PieChart = snapshot.val();
    return pieChart;
  } catch (error) {
    return null;
  }
};

export default getPieChart;
