import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_SCURVE_STATE } from '../types/sCurveState';
import {
  IsUpdatingPayload,
  ReflectionPayload,
  ActivityIdPayload,
  SCurveGoalPayload,
  SCurveStagePayload,
  SCurvePayload,
} from '../types/payloads';
import { RootState } from '../store';

export const SCURVE_SLICE_KEY = 'sCurve';

export const sCurveSlice = createSlice({
  name: SCURVE_SLICE_KEY,
  initialState: INITIAL_SCURVE_STATE,
  reducers: {
    setIsUpdating: (state, action: PayloadAction<IsUpdatingPayload>) => {
      state.isUpdating = action.payload.isUpdating;
    },
    setSCurve: (state, action: PayloadAction<SCurvePayload>) => {
      state.sCurve = action.payload.sCurve;
    },
  },
});

export const {
  setIsUpdating: setSCurveIsUpdating,
  setSCurve,
} = sCurveSlice.actions;

export const sCurveCreateNewItem = createAction<ActivityIdPayload>(
  'sCurveCreateNewItem',
);

export const sCurveLoadExistingItem = createAction<ActivityIdPayload>(
  'sCurveLoadExistingItem',
);

export const sCurveUpdateGoal = createAction<SCurveGoalPayload>(
  'sCurveUpdateGoal',
);

export const sCurveUpdateStage = createAction<SCurveStagePayload>(
  'sCurveUpdateStage',
);

export const sCurveUpdateReflection = createAction<ReflectionPayload>(
  'sCurveUpdateReflection',
);
export const sCurveSubmit = createAction('sCurveSubmit');

export const selectSCurveState = (state: RootState) => state[SCURVE_SLICE_KEY];
export const selectSCurveIsUpdating = (state: RootState) =>
  selectSCurveState(state)?.isUpdating;
export const selectSCurve = (state: RootState) =>
  selectSCurveState(state)?.sCurve;
export const selectSCurveGoal = (state: RootState) => selectSCurve(state)?.goal;
export const selectSCurveStage = (state: RootState) =>
  selectSCurve(state)?.stage;
export const selectSCurveReflection = (state: RootState) =>
  selectSCurve(state)?.reflection;
export const selectSCurveId = (state: RootState) => selectSCurve(state)?.id;
export default sCurveSlice.reducer;
