import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_WPLH_STATE } from '../types/wplhState';

import {
  IsUpdatingPayload,
  WPLHPayload,
  ReflectionPayload,
  ActivityIdPayload,
  WPLHLevelsPayload,
} from '../types/payloads';
import { RootState } from '../store';

export const WPLH_SLICE_KEY = 'wplh';

export const WPLHSlice = createSlice({
  name: WPLH_SLICE_KEY,
  initialState: INITIAL_WPLH_STATE,
  reducers: {
    setIsUpdating: (state, action: PayloadAction<IsUpdatingPayload>) => {
      state.isUpdating = action.payload.isUpdating;
    },
    setWPLH: (state, action: PayloadAction<WPLHPayload>) => {
      state.wplh = action.payload.wplh;
    },
  },
});

export const { setIsUpdating: setWPLHIsUpdating, setWPLH } = WPLHSlice.actions;

export const wplhCreateNewItem = createAction<ActivityIdPayload>(
  'wplhCreateNewItem',
);
export const wplhLoadExistingItem = createAction<ActivityIdPayload>(
  'wplhLoadExistingItem',
);

export const wplhUpdateLevels = createAction<WPLHLevelsPayload>(
  'wplhUpdateLevels',
);

export const wplhUpdateReflection = createAction<ReflectionPayload>(
  'wplhUpdateReflection',
);
export const wplhSubmit = createAction('wplhSubmit');

// Other code such as selectors can use the imported `RootState` type
export const selectWPLHState = (state: RootState) => state[WPLH_SLICE_KEY];
export const selectWPLHIsUpdating = (state: RootState) =>
  selectWPLHState(state)?.isUpdating;
export const selectWPLH = (state: RootState) => selectWPLHState(state)?.wplh;
export const selectWPLHWork = (state: RootState) =>
  selectWPLH(state)?.workLevel;
export const selectWPLHPlay = (state: RootState) =>
  selectWPLH(state)?.playLevel;
export const selectWPLHLove = (state: RootState) =>
  selectWPLH(state)?.loveLevel;
export const selectWPLHHealth = (state: RootState) =>
  selectWPLH(state)?.healthLevel;
export const selectWPLHid = (state: RootState) => selectWPLH(state)?.id;
export const selectReflection = (state: RootState) =>
  selectWPLH(state)?.reflection;
export default WPLHSlice.reducer;
