import firebase from 'gatsby-plugin-firebase';
import { WPLHDashboard } from '../../../types/journalItems/WPLHDashboard';

const updateWPLHDashboard = async (wplhDashboard: WPLHDashboard) => {
  await firebase
    .database()
    .ref(`WPLH/${wplhDashboard.id}`)
    .update({
      ...wplhDashboard,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
};

export default updateWPLHDashboard;
