import firebase from 'gatsby-plugin-firebase';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const getResumeSharing = async (id: string): Promise<boolean | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`resumes/${id}/public`)
      .once('value');
    const isPublic: ResumeDatabaseObject['public'] = snapshot.val();
    return isPublic;
  } catch (error) {
    return null;
  }
};

export default getResumeSharing;
