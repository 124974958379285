import { get, isEqual } from 'lodash';
import { EntryTypeEnum } from '../types/EntryType';
import { Resume } from '../types/Resume';
import { Entry } from '../types/Timeline';
import uuid from './uuid';

export default function extractTimelineEntryUpdates(
  path: string,
  prevResume: Resume,
  currResume: Resume,
  resumeId: string,
  versionId: string,
): Entry[] {
  const previousItems = get(prevResume, path) ?? [];
  const currentItems = get(currResume, path) ?? [];
  type newUpdate = {
    updateType: string;
    updatedItem: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
  const allUpdates: Array<newUpdate> = new Array<newUpdate>();
  const updateEntries: Array<Entry> = [];
  const { name } = currResume;
  const type = EntryTypeEnum.resume;

  const prevItemsMap = new Map();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousItems.forEach((item: any) => {
    const { id } = item;
    prevItemsMap.set(id, item);
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentItems.forEach((item: any) => {
    const { id } = item;
    if (prevItemsMap.has(id)) {
      if (!isEqual(item, prevItemsMap.get(id))) {
        allUpdates.push({ updateType: 'edit', updatedItem: item });
      }
    } else {
      allUpdates.push({ updateType: 'add', updatedItem: item });
    }

    prevItemsMap.delete(id);
  });

  Object.values(prevItemsMap).forEach((item) => {
    allUpdates.push({ updateType: 'delete', updatedItem: item });
  });

  allUpdates.forEach((item) => {
    let description = '';
    let title = '';

    if (path === 'work.items') {
      title = `${item.updatedItem.position} of ${item.updatedItem.company}`;
      if (item.updateType === 'add') {
        description = `Added New Experience to ${name}`;
      } else if (item.updateType === 'edit') {
        description = `Updated Experience to ${name}`;
      }
    } else if (path === 'education.items') {
      title = `${item.updatedItem.degree} at ${item.updatedItem.institution}`;
      if (item.updateType === 'add') {
        description = `Added New Education to ${name}`;
      } else if (item.updateType === 'edit') {
        description = `Updated Education to ${name}`;
      }
    } else if (path === 'skills.items') {
      if (item.updateType === 'add') {
        title = `${item.updatedItem.name}`;
        description = `Added New Skill to ${name}`;
      }
    } else if (path === 'certifications.items') {
      if (item.updateType === 'add') {
        title = `${item.updatedItem.title}`;
        description = `Added New Certification to ${name}`;
      }
    }

    if (description.length > 0 && title.length > 0) {
      const entryId = uuid();
      const entry: Entry = {
        resourceId: resumeId,
        versionId,
        date: Date.now(),
        title,
        description,
        type,
        entryId,
      };
      updateEntries.push(entry);
    }
  });
  return updateEntries;
}
