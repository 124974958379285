import { WPLHDashboard } from '../../types/journalItems/WPLHDashboard';

export type WPLHState = {
  isUpdating: boolean;
  wplh: WPLHDashboard | null;
};

export const INITIAL_WPLH_STATE = {
  isUpdating: false,
  wplh: null,
} as WPLHState;
