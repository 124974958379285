import firebase from 'gatsby-plugin-firebase';

import React, { useEffect, createContext, memo } from 'react';

import useDispatch from '../hooks/useAppDispatch';
import { setUser, setError, clearError } from '../redux/slices/userSlice';

const AuthContext = createContext(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (firebase.auth() === undefined) return;

    const unsubscribe = firebase.auth().onAuthStateChanged(
      (firebaseUser) => {
        dispatch(clearError());
        if (firebaseUser != null) {
          const { uid, email, displayName, isAnonymous } = firebaseUser;
          dispatch(setUser({ user: { uid, email, displayName, isAnonymous } }));
        }
      },
      (error) => {
        dispatch(setError({ error }));
      },
    );

    return () => {
      unsubscribe();
    };
  }, [firebase.auth()]);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};

export default AuthContext;

const memoizedProvider = memo(AuthProvider);

export { memoizedProvider as AuthProvider };
