import firebase from 'gatsby-plugin-firebase';
import { JournalEntry } from '../../types/journalItems/JournalEntry';

const getJournalEntry = async (id: string): Promise<JournalEntry | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`journalentries/${id}`)
      .once('value');
    const journalEntry: JournalEntry = snapshot.val();
    return journalEntry;
  } catch (error) {
    return null;
  }
};

export default getJournalEntry;
