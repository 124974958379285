import { Resume } from '../types/Resume';

const defaultLayouts: Resume['metadata']['layout'] = {
  pikachu: [
    ['skills', 'languages', 'certifications', 'awards', 'hobbies'],
    ['work', 'education', 'projects', 'volunteer'],
  ],
  zetybanking: [
    [
      'summary',
      'work',
      'education',
      'skills',
      'languages',
      'certifications',
      'awards',
      'projects',
      'volunteer',
      'hobbies',
    ],
  ],
  wallStreet: [
    ['work', 'education', 'projects', 'volunteer', 'certifications', 'awards'],
    ['skills', 'languages', 'hobbies'],
  ],
  zetymanagement: [
    ['skills', 'languages', 'certifications', 'awards', 'hobbies'],
    ['summary', 'work', 'education', 'projects', 'volunteer'],
  ],
  zetydataanalyst: [
    [
      'work',
      'education',
      'skills',
      'languages',
      'certifications',
      'awards',
      'projects',
      'volunteer',
      'hobbies',
    ],
  ],
};

export default defaultLayouts;
