import firebase from 'gatsby-plugin-firebase';
import { Resume } from '../../types/Resume';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const getLatestResume = async (id: string): Promise<Resume | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`resumes/${id}`)
      .once('value');
    const resumeObj: ResumeDatabaseObject = snapshot.val();
    const latestResume: Resume = resumeObj.versions[resumeObj.latestVersion];
    return latestResume;
  } catch (error) {
    return null;
  }
};

export default getLatestResume;
