import { fork } from 'redux-saga/effects';

import resumeSaga from './resumeSaga';
import timelineSaga from './timelineSaga';
import userSaga from './userSaga';
import journalEntrySaga from './journalEntrySaga';
import pieChartSaga from './pieChartSaga';
import WPLHSaga from './WPLHSaga';
import sCurveSaga from './sCurveSaga';

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(resumeSaga);
  yield fork(timelineSaga);
  yield fork(journalEntrySaga);
  yield fork(pieChartSaga);
  yield fork(WPLHSaga);
  yield fork(sCurveSaga);
}
