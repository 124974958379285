import { get, isUndefined } from 'lodash';
import { Resume } from '../types/Resume';

export default function getResumeSectionFromPath<T>(
  resume: Resume,
  path: string,
  fallback?: T,
): T | Resume {
  const value = get(resume, path);

  if (isUndefined(value)) {
    return isUndefined(fallback) ? resume : fallback;
  }

  return value;
}
