import firebase from 'gatsby-plugin-firebase';
import { isError } from 'lodash';
import { toast } from 'react-toastify';

const loginAnonymously = async (): Promise<firebase.auth.UserCredential | void> => {
  try {
    return await firebase.auth().signInAnonymously();
  } catch (error) {
    if (isError(error)) {
      toast.error(error.message);
    }
  }
};

export default loginAnonymously;
