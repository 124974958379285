import firebase from 'gatsby-plugin-firebase';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const updateResumeSharing = async (resumeId: string, isPublic: boolean) => {
  try {
    // Get db object
    const snapshot = await firebase
      .database()
      .ref(`resumes/${resumeId}`)
      .once('value');
    const resumeObj: ResumeDatabaseObject = snapshot.val();
    // Update resume with isPublic parameter
    await firebase
      .database()
      .ref(`resumes/${resumeId}`)
      .update({
        ...resumeObj,
        public: isPublic,
      });
  } catch (error) {
    return null;
  }
};

export default updateResumeSharing;
