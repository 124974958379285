import { useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';
import {
  removeUser,
  setUserLoading,
  selectUser,
  selectUserIsLoading,
} from '../redux/slices/userSlice';
import loginWithGoogleFirebase from '../api/user/loginWithGoogle';
import loginAnonymouslyFirebase from '../api/user/loginAnonymously';
import logoutFirebase from '../api/user/logout';
import deleteAccountFirebase from '../api/user/deleteAccount';

import { User } from '../types/User';

export default function useUser() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectUserIsLoading);

  const addUserToDatabaseIfNew = async (remoteUser: User) => {
    const userRef = firebase.database().ref(`users/${remoteUser.uid}`);
    const snapshot = await userRef.once('value');
    !snapshot.val() && userRef.set(remoteUser); // check if user is already in database
  };

  useEffect(() => {
    if (user && !isLoading) {
      addUserToDatabaseIfNew(user);
    }
  }, [user, isLoading]);

  const loginWithGoogle = async () => {
    await loginWithGoogleFirebase();
  };

  const loginAnonymously = async () => {
    await loginAnonymouslyFirebase();
  };

  const logout = () => {
    dispatch(setUserLoading({ isLoading: true }));
    logoutFirebase();
    dispatch(removeUser());
    navigate('/');
  };

  const deleteAccount = async () => {
    dispatch(removeUser());
    await deleteAccountFirebase();
    navigate('/');
  };

  return {
    loginWithGoogle,
    loginAnonymously,
    logout,
    deleteAccount,
    user,
    isLoading,
  };
}
