export type JournalItem = {
  id: string;
  user: string;
  createdAt: number;
  updatedAt: number;
  title: string;
  type: JournalItemType;
  isSubmitted: boolean;
};

export enum JournalItemEnum {
  entry = 'entry',
  activity = 'activity',
}

export type JournalItemType = keyof typeof JournalItemEnum;
