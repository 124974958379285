import { NoIDResume } from '../types/Resume';
import defaultLayouts from './defaultLayouts';

const initialState: NoIDResume = {
  profile: {
    heading: 'Profile',
    photograph: '',
    firstName: '',
    lastName: '',
    subtitle: '',
  },
  contact: {
    heading: 'Contact',
    email: '',
    phone: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      pincode: '',
    },
    website: '',
    linkedin: '',
  },
  summary: {
    heading: 'Summary',
    visible: true,
    body: '',
  },
  work: {
    heading: 'Work Experience',
    visible: true,
    items: [],
  },
  education: {
    heading: 'Education',
    visible: true,
    items: [],
  },
  projects: {
    heading: 'Projects',
    visible: true,
    items: [],
  },
  volunteer: {
    heading: 'Volunteer',
    visible: true,
    items: [],
  },
  awards: {
    heading: 'Awards',
    visible: true,
    items: [],
  },
  certifications: {
    heading: 'Certifications',
    visible: true,
    items: [],
  },
  skills: {
    heading: 'Skills',
    visible: true,
    items: [],
  },
  hobbies: {
    heading: 'Interests',
    visible: true,
    items: [],
  },
  languages: {
    heading: 'Languages',
    visible: true,
    items: [],
  },
  metadata: {
    language: 'en',
    template: 'zetybanking',
    font: 'Montserrat',
    layout: defaultLayouts,
    colors: {
      text: '#444444',
      primary: '#5875DB',
      background: '#FFFFFF',
    },
    margins: {
      lineHeight: '1.3',
      fontSize: '9',
      lrMargins: '15',
      tbMargins: '15',
    },
  },
  public: true,
};

export default initialState;
