import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import userReducer from './slices/userSlice';
import resumeReducer from './slices/resumeSlice';
import timelineReducer from './slices/timelineSlice';
import journalEntryReducer from './slices/journalEntrySlice';
import pieChartReducer from './slices/pieChartSlice';
import wplhReducer from './slices/wplhSlice';
import sCurveReducer from './slices/sCurveSlice';

import rootSaga from './sagas/rootSaga';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    user: userReducer,
    resume: resumeReducer,
    timeline: timelineReducer,
    journalEntry: journalEntryReducer,
    pieChart: pieChartReducer,
    wplh: wplhReducer,
    sCurve: sCurveReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
