import { put } from 'redux-saga/effects';
import { setUser } from '../slices/userSlice';
import hasWindow from '../../utils/hasWindow';

function* userSaga() {
  if (!hasWindow()) {
    return;
  }

  const localStorageUser = localStorage.getItem('user');
  if (localStorageUser == null) {
    return;
  }
  const user = JSON.parse(localStorageUser);
  yield put(setUser({ user }));
}

export default userSaga;
