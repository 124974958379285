import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_USER_STATE } from '../types/userState';

import {
  AuthErrorPayload,
  IsLoadingPayload,
  UserPayload,
} from '../types/payloads';
import { RootState } from '../store';

export const USER_SLICE_KEY = 'user';

export const userSlice = createSlice({
  name: USER_SLICE_KEY,
  initialState: INITIAL_USER_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<IsLoadingPayload>) => {
      state.isLoading = action.payload.isLoading;
    },
    setUser: (state, action: PayloadAction<UserPayload>) => {
      const { user } = action.payload;
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    removeUser: (state) => {
      state.user = undefined;
      state.isLoading = false;
      localStorage.removeItem('user');
    },
    setError: (state, action: PayloadAction<AuthErrorPayload>) => {
      state.error = action.payload.error;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
});

export const {
  setLoading: setUserLoading,
  setUser,
  removeUser,
  setError,
  clearError,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserState = (state: RootState) => state[USER_SLICE_KEY];
export const selectUser = (state: RootState) => selectUserState(state).user;
export const selectUserIsLoading = (state: RootState) =>
  selectUserState(state).isLoading;

export default userSlice.reducer;
