import { call, put, takeEvery, select } from 'redux-saga/effects';

import { Entry } from '../../types/Timeline';
import { EntryTypeEnum } from '../../types/EntryType';
import { User } from '../../types/User';

import {
  selectTimelineResumeShouldUpdate,
  selectTimelineResumeUpdateEntries,
  setShouldUpdateTimeline,
  setUpdateTimelineCertificationEntries,
  setUpdateTimelineEducationEntries,
  setUpdateTimelineSkillEntries,
  setUpdateTimelineWorkEntries,
} from '../slices/timelineSlice';
import {
  resumeCreated,
  resumeOnOpen,
  resumeUpdatedItem,
  selectResume,
  selectResumeId,
  selectResumePreviousVersion,
  selectVersionId,
} from '../slices/resumeSlice';
import {
  selectJournalEntry,
  journalEntrySubmit,
  setJournalEntry,
} from '../slices/journalEntrySlice';
import { selectUser } from '../slices/userSlice';
import {
  NamePayload,
  Payload,
  ResumeIdPayload,
  ResumeItemPayload,
  UserPayload,
} from '../types/payloads';
import { Resume } from '../../types/Resume';
import extractTimelineEntryUpdates from '../../utils/extractTimelineEntryUpdates';
import createTimelineEntries from '../../api/timeline/createTimelineEntries';
import deleteTimelineEntries from '../../api/timeline/deleteTimelineEntries';
import createTimelineEntry from '../../api/timeline/createTimelineEntry';
import updateTimelineEntry from '../../api/timeline/updateTimelineEntry';

import { ResumeUpdateEntries } from '../types/timelineState';
import uuid from '../../utils/uuid';
import { JournalEntry } from '../../types/journalItems/JournalEntry';
import { pieChartSubmit, selectPieChart } from '../slices/pieChartSlice';
import { PieChart } from '../../types/journalItems/PieChart';
import { wplhSubmit, selectWPLH } from '../slices/wplhSlice';
import { WPLHDashboard } from '../../types/journalItems/WPLHDashboard';
import { sCurveSubmit, selectSCurve } from '../slices/sCurveSlice';

function* updateResumeTimelineEntries() {
  const shouldUpdate: boolean = yield select(selectTimelineResumeShouldUpdate);

  if (shouldUpdate === false) {
    return;
  }

  const updatedTimelineEntries: ResumeUpdateEntries | null = yield select(
    selectTimelineResumeUpdateEntries,
  );

  const currentUser: User = yield select(selectUser);
  const versionId: string = yield select(selectVersionId);
  const resumeId: string = yield select(selectResumeId);
  const { uid } = currentUser;

  if (updatedTimelineEntries == null) {
    return;
  }

  const updatedTimelineEntriesArr = Object.values(
    updatedTimelineEntries,
  ).reduce((collector, entries) => {
    collector.push(...entries);
    return collector;
  }, [] as Entry[]);

  yield call(deleteTimelineEntries, {
    userId: uid,
    resourceId: resumeId,
    versionId,
  });

  yield call(createTimelineEntries, {
    userId: uid,
    entries: updatedTimelineEntriesArr,
  });

  yield put(setShouldUpdateTimeline({ shouldUpdate: false }));
}

function* getResumeTimelineUpdate({ payload }: Payload<ResumeItemPayload>) {
  const { path } = payload;

  const prevResume: Resume | null = yield select(selectResumePreviousVersion);
  const currResume: Resume | null = yield select(selectResume);
  const versionId: string = yield select(selectVersionId);
  const resumeId: string = yield select(selectResumeId);

  if (prevResume == null || currResume == null) {
    return;
  }

  const updatedEntries = extractTimelineEntryUpdates(
    path,
    prevResume,
    currResume,
    resumeId,
    versionId,
  );
  if (path === 'work.items') {
    yield put(setUpdateTimelineWorkEntries({ entries: updatedEntries }));
  } else if (path === 'education.items') {
    yield put(setUpdateTimelineEducationEntries({ entries: updatedEntries }));
  } else if (path === 'skills.items') {
    yield put(setUpdateTimelineSkillEntries({ entries: updatedEntries }));
  } else if (path === 'certifications.items') {
    yield put(
      setUpdateTimelineCertificationEntries({ entries: updatedEntries }),
    );
  } else {
    return;
  }

  yield put(setShouldUpdateTimeline({ shouldUpdate: true }));
}

function* resetResumeTimelineUpdate() {
  yield put(setUpdateTimelineWorkEntries({ entries: [] }));
  yield put(setUpdateTimelineEducationEntries({ entries: [] }));
  yield put(setUpdateTimelineSkillEntries({ entries: [] }));
  yield put(setUpdateTimelineCertificationEntries({ entries: [] }));
  yield put(setShouldUpdateTimeline({ shouldUpdate: false }));
}

function* createResumeCreatedTimelineEntry({
  payload,
}: Payload<NamePayload & UserPayload & ResumeIdPayload>) {
  const { name, user, resumeId } = payload;
  const entryId = uuid();

  const newResumeEntry: Entry = {
    entryId,
    resourceId: resumeId,
    date: Date.now(),
    title: `Created new Resume - ${name}`,
    description: '',
    type: EntryTypeEnum.resume,
  };

  yield call(createTimelineEntry, {
    userId: user.uid,
    entry: newResumeEntry,
  });
}

function* createJournalTimelineEntry() {
  const journalEntry: JournalEntry = yield select(selectJournalEntry);
  if (journalEntry == null || journalEntry.isSubmitted) {
    return;
  }

  const entry: Entry = {
    resourceId: journalEntry.id,
    date: Date.now(),
    title: journalEntry.title,
    description: 'New Journal Created',
    type: EntryTypeEnum.journal,
    entryId: journalEntry.id,
  };

  yield call(createTimelineEntry, {
    userId: journalEntry.user,
    entry,
  });
}

function* updateJournalTimelineEntry() {
  const journalEntry: JournalEntry = yield select(selectJournalEntry);
  if (journalEntry == null || !journalEntry.isSubmitted) {
    return;
  }
  const entry: Entry = {
    resourceId: journalEntry.id,
    date: Date.now(),
    title: journalEntry.title,
    description: 'New Journal Created',
    type: EntryTypeEnum.journal,
    entryId: journalEntry.id,
  };

  yield call(updateTimelineEntry, {
    userId: journalEntry.user,
    entryId: entry.entryId,
    entry,
  });
}

function* createPieChartTimelineEntry() {
  const currentPieChart: PieChart = yield select(selectPieChart);

  if (currentPieChart == null || currentPieChart.isSubmitted) {
    return;
  }

  const { id, isSubmitted } = currentPieChart;
  if (isSubmitted) {
    return;
  }

  const entryId = uuid();

  const entry: Entry = {
    resourceId: id,
    date: Date.now(),
    title: 'My Pie Chart',
    description: 'Pie Chart Submitted',
    type: EntryTypeEnum.piechart,
    entryId,
  };

  yield call(createTimelineEntry, {
    userId: currentPieChart.user,
    entry,
  });
}

function* createWPLHTimelineEntry() {
  const currentWPLH: WPLHDashboard = yield select(selectWPLH);

  if (currentWPLH == null || currentWPLH.isSubmitted) {
    return;
  }

  const { id } = currentWPLH;
  const entryId = uuid();

  const entry: Entry = {
    resourceId: id,
    date: Date.now(),
    title: 'My Work Play Love Health Dashboard',
    description: 'My Work Play Love Health Dashboard Submitted',
    type: EntryTypeEnum.wplh,
    entryId,
  };

  yield call(createTimelineEntry, {
    userId: currentWPLH.user,
    entry,
  });
}

function* createSCurveTimelineEntry() {
  const currentSCurve: WPLHDashboard = yield select(selectSCurve);

  if (currentSCurve == null || currentSCurve.isSubmitted) {
    return;
  }

  const { id } = currentSCurve;
  const entryId = uuid();

  const entry: Entry = {
    resourceId: id,
    date: Date.now(),
    title: 'My SCurve Activity',
    description: 'SCurve Activity Submitted',
    type: EntryTypeEnum.scurve,
    entryId,
  };

  yield call(createTimelineEntry, {
    userId: currentSCurve.user,
    entry,
  });
}

function* timelineSaga() {
  yield takeEvery(setShouldUpdateTimeline, updateResumeTimelineEntries);
  yield takeEvery(resumeUpdatedItem, getResumeTimelineUpdate);
  yield takeEvery(resumeOnOpen, resetResumeTimelineUpdate);
  yield takeEvery(resumeCreated, createResumeCreatedTimelineEntry);
  yield takeEvery(journalEntrySubmit, createJournalTimelineEntry);
  yield takeEvery(setJournalEntry, updateJournalTimelineEntry);
  yield takeEvery(pieChartSubmit, createPieChartTimelineEntry);
  yield takeEvery(wplhSubmit, createWPLHTimelineEntry);
  yield takeEvery(sCurveSubmit, createSCurveTimelineEntry);
}

export default timelineSaga;
