import firebase from 'gatsby-plugin-firebase';
import { Entry } from '../../types/Timeline';

const updateTimelineEntry = async (args: {
  userId: string;
  entryId: string;
  entry: Entry;
}) => {
  const { userId, entryId, entry } = args;
  await firebase
    .database()
    .ref(`timelines/${userId}/${entryId}`)
    .update({
      ...entry,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
};

export default updateTimelineEntry;
