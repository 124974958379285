import firebase from 'gatsby-plugin-firebase';
import { WPLHDashboard } from '../../../types/journalItems/WPLHDashboard';
import { JournalItemEnum } from '../../../types/JournalItem';

const createWPLH = async (args: {
  user: string;
  id: string;
  createdAt?: number;
  workLevel?: number;
  playLevel?: number;
  loveLevel?: number;
  healthLevel?: number;
  reflection?: string;
}): Promise<void> => {
  const {
    user,
    id,
    createdAt,
    workLevel,
    playLevel,
    loveLevel,
    healthLevel,
    reflection,
  } = args;

  const newCreatedAt = createdAt ?? firebase.database.ServerValue.TIMESTAMP;
  const newWorkLevel = workLevel ?? 0;
  const newPlayLevel = playLevel ?? 0;
  const newLoveLevel = loveLevel ?? 0;
  const newHealthLevel = healthLevel ?? 0;
  const newReflection = reflection ?? '';

  const wplhDashboard: WPLHDashboard = {
    id,
    user,
    createdAt: newCreatedAt as number,
    updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
    workLevel: newWorkLevel,
    playLevel: newPlayLevel,
    loveLevel: newLoveLevel,
    healthLevel: newHealthLevel,
    reflection: newReflection,
    type: JournalItemEnum.activity,
    title: 'WPLH',
    isSubmitted: false,
  };

  firebase.database().ref(`WPLH/${id}`).set(wplhDashboard);
};

export default createWPLH;
