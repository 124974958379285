const ModalEvents = {
  AUTH_MODAL: 'auth_modal',
  CREATE_RESUME_MODAL: 'create_resume_modal',
  WORK_MODAL: 'work_modal',
  EDUCATION_MODAL: 'education_modal',
  PROJECT_MODAL: 'project_modal',
  VOLUNTEER_MODAL: 'volunteer_modal',
  AWARD_MODAL: 'award_modal',
  CERTIFICATION_MODAL: 'certification_modal',
  SKILL_MODAL: 'skill_modal',
  HOBBY_MODAL: 'hobby_modal',
  LANGUAGE_MODAL: 'language_modal',
  REFERENCE_MODAL: 'reference_modal',
  IMPORT_MODAL: 'import_modal',
  REVERT_VERSION_MODAL: 'revert_version_modal',
  WPLH_MODAL: 'wplh_modal',
  PIECHART_MODAL: 'piechart_modal',
  SCURVE_MODAL: 'scurve_modal',
  DELETE_ACCOUNT_MODAL: 'delete_account_modal',
  LOGOUT_MODAL: 'logout_modal',
  DELETE_TIMELINE_ENTRY_MODAL: 'delete_timeline_entry_modal',
  DELETE_JOURNAL_ENTRY_MODAL: 'delete_journal_entry_modal',
};

export default ModalEvents;
