import {
  call,
  put,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import updateJournalEntry from '../../api/journal/updateJournalEntry';

import {
  selectJournalEntry,
  setJournalEntryIsUpdating,
  setJournalEntry,
  journalEntrySubmit,
  journalEntryReset,
  journalEntryCreateNewItem,
  journalEntryLoadExistingItem,
  journalEntryUpdateDetails,
} from '../slices/journalEntrySlice';

import { JournalEntry } from '../../types/journalItems/JournalEntry';
import { JournalItemEnum } from '../../types/JournalItem';
import { JournalDetailsPayload, JournalIdPayload } from '../types/payloads';
import createJournalEntry from '../../api/journal/createJournalEntry';
import getJournalEntry from '../../api/journal/getJournalEntry';
import { User } from '../../types/User';
import { selectUser } from '../slices/userSlice';

const DEBOUNCE_WAIT_TIME = 500;

function* updateJournalEntryDebounced() {
  yield delay(DEBOUNCE_WAIT_TIME);

  const currentJournal: JournalEntry = yield select(selectJournalEntry);

  if (currentJournal.id === '') {
    return;
  }

  yield put(setJournalEntryIsUpdating({ isUpdating: true }));
  yield call(updateJournalEntry, currentJournal);
  yield put(setJournalEntryIsUpdating({ isUpdating: false }));
}

function* createNewJournalEntry({ payload }: Payload<JournalIdPayload>) {
  const { journalId } = payload;
  const user: User | null = yield select(selectUser);

  const newJournalEntry: JournalEntry = {
    id: journalId,
    user: user?.uid ?? '',
    createdAt: Date.now(),
    content: '',
    title: '',
    type: JournalItemEnum.entry,
    isSubmitted: false,
    updatedAt: Date.now(),
  };

  const { user: uid, id, title, content, createdAt } = newJournalEntry;

  yield call(createJournalEntry, { uid, id, title, content, createdAt });
  yield put(setJournalEntry({ journalEntry: newJournalEntry }));
}

function* loadExistingJournalEntry({ payload }: Payload<JournalIdPayload>) {
  const { journalId } = payload;
  const loadedJournalEntry: JournalEntry = yield call(
    getJournalEntry,
    journalId,
  );
  yield put(setJournalEntry({ journalEntry: loadedJournalEntry }));
}

function* updateJournalEntryDetails({
  payload,
}: Payload<JournalDetailsPayload>) {
  const currentJournal: JournalEntry | null = yield select(selectJournalEntry);
  if (currentJournal == null) {
    return;
  }
  const { title, content, createdAt } = payload;
  yield put(
    setJournalEntry({
      journalEntry: {
        ...currentJournal,
        title,
        content,
        createdAt,
      },
    }),
  );
}

function* submitJournalEntry() {
  const currentJournal: JournalEntry | null = yield select(selectJournalEntry);
  if (currentJournal == null) {
    return;
  }
  yield put(
    setJournalEntry({
      journalEntry: {
        ...currentJournal,
        isSubmitted: true,
      },
    }),
  );
}

function* resetJournalEntry() {
  yield put(
    setJournalEntry({
      journalEntry: null,
    }),
  );
}

function* journalEntrySaga() {
  yield takeLatest(setJournalEntry, updateJournalEntryDebounced);
  yield takeEvery(journalEntryCreateNewItem, createNewJournalEntry);
  yield takeEvery(journalEntryLoadExistingItem, loadExistingJournalEntry);
  yield takeEvery(journalEntryUpdateDetails, updateJournalEntryDetails);
  yield takeEvery(journalEntrySubmit, submitJournalEntry);
}

export default journalEntrySaga;
