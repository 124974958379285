import firebase from 'gatsby-plugin-firebase';
import { SCurve } from '../../../types/journalItems/SCurve';

const updateSCurve = async (sCurve: SCurve) => {
  await firebase
    .database()
    .ref(`scurve/${sCurve.id}`)
    .update({
      ...sCurve,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
};

export default updateSCurve;
