import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ShouldUpdatePayload,
  ResumeUpdatesPayload,
  TimelinePayload,
  TimelineEntriesPayload,
} from '../types/payloads';
import {
  INITIAL_TIMELINE_STATE,
  ResumeUpdateEntries,
} from '../types/timelineState';
import { RootState } from '../store';

export const TIMELINE_SLICE_KEY = 'timeline';

export const timelineSlice = createSlice({
  name: TIMELINE_SLICE_KEY,
  initialState: INITIAL_TIMELINE_STATE,
  reducers: {
    setShouldUpdateTimeline: (
      state,
      action: PayloadAction<ShouldUpdatePayload>,
    ) => {
      state.resumeUpdates.shouldUpdate = action.payload.shouldUpdate;
    },
    setUpdateResume: (state, action: PayloadAction<ResumeUpdatesPayload>) => {
      state.resumeUpdates.entries = action.payload.entry;
    },
    setTimeline: (state, action: PayloadAction<TimelinePayload>) => {
      state.timeline = action.payload.timeline;
    },
    setUpdateTimelineWorkEntries: (
      state,
      action: PayloadAction<TimelineEntriesPayload>,
    ) => {
      if (state.resumeUpdates.entries == null) {
        state.resumeUpdates.entries = emptyResumeUpdateEntries;
      }
      state.resumeUpdates.entries.work = action.payload.entries;
    },
    setUpdateTimelineSkillEntries: (
      state,
      action: PayloadAction<TimelineEntriesPayload>,
    ) => {
      if (state.resumeUpdates.entries == null) {
        state.resumeUpdates.entries = emptyResumeUpdateEntries;
      }
      state.resumeUpdates.entries.skills = action.payload.entries;
    },
    setUpdateTimelineCertificationEntries: (
      state,
      action: PayloadAction<TimelineEntriesPayload>,
    ) => {
      if (state.resumeUpdates.entries == null) {
        state.resumeUpdates.entries = emptyResumeUpdateEntries;
      }
      state.resumeUpdates.entries.certifications = action.payload.entries;
    },
    setUpdateTimelineEducationEntries: (
      state,
      action: PayloadAction<TimelineEntriesPayload>,
    ) => {
      if (state.resumeUpdates.entries == null) {
        state.resumeUpdates.entries = emptyResumeUpdateEntries;
      }
      state.resumeUpdates.entries.education = action.payload.entries;
    },
  },
});

const emptyResumeUpdateEntries: ResumeUpdateEntries = {
  skills: [],
  certifications: [],
  education: [],
  work: [],
};

export const selectTimelineState = (state: RootState) =>
  state[TIMELINE_SLICE_KEY];
export const selectTimeline = (state: RootState) =>
  selectTimelineState(state)?.timeline;
export const selectResumeUpdatesSkills = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates?.entries?.skills;
export const selectResumeUpdatesCertifications = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates?.entries?.certifications;
export const selectResumeUpdatesEducation = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates?.entries?.education;
export const selectResumeUpdatesWork = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates?.entries?.work;
export const selectTimelineResumeUpdateEntries = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates?.entries;
export const selectTimelineResumeShouldUpdate = (state: RootState) =>
  selectTimelineState(state)?.resumeUpdates.shouldUpdate;

export default timelineSlice.reducer;

export const {
  setShouldUpdateTimeline,
  setUpdateResume,
  setTimeline,
  setUpdateTimelineWorkEntries,
  setUpdateTimelineCertificationEntries,
  setUpdateTimelineEducationEntries,
  setUpdateTimelineSkillEntries,
} = timelineSlice.actions;
