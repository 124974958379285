import firebase from 'gatsby-plugin-firebase';
import { toast } from 'react-toastify';
import { isError } from 'lodash';
import loginWithGoogle from './loginWithGoogle';
import getFirebaseErrorCode from '../../utils/getFirebaseErrorCode';

const deleteAccount = async (): Promise<void> => {
  const { currentUser } = firebase.auth();

  if (currentUser == null) {
    toast.error("No user logged in, can't delete");
    return;
  }

  // delete user data
  try {
    // delete user's resumes data
    firebase
      .database()
      .ref('resumes')
      .orderByChild('user')
      .equalTo(currentUser.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          const resumeKeys = Object.keys(data);
          resumeKeys.forEach(async (resumeKey) => {
            await firebase.database().ref(`resumes/${resumeKey}`).remove();
          });
        }
      });

    // delete user's timeline data
    await firebase.database().ref(`timelines/${currentUser.uid}`).remove();

    // delete user's journal entry data
    firebase
      .database()
      .ref('journalentries')
      .orderByChild('user')
      .equalTo(currentUser.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          const journalEntryKeys = Object.keys(data);
          journalEntryKeys.forEach(async (journalEntryKey) => {
            await firebase
              .database()
              .ref(`journalentries/${journalEntryKey}`)
              .remove();
          });
        }
      });

    // delete user's pie chart data
    firebase
      .database()
      .ref('piechart')
      .orderByChild('user')
      .equalTo(currentUser.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          const pieChartKeys = Object.keys(data);
          pieChartKeys.forEach(async (pieChartKey) => {
            await firebase.database().ref(`piechart/${pieChartKey}`).remove();
          });
        }
      });

    // delete user's WPLH data
    firebase
      .database()
      .ref('WPLH')
      .orderByChild('user')
      .equalTo(currentUser.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          const wplhKeys = Object.keys(data);
          wplhKeys.forEach(async (wplhKey) => {
            await firebase.database().ref(`WPLH/${wplhKey}`).remove();
          });
        }
      });

    // delete user data
    await firebase.database().ref(`users/${currentUser.uid}`).remove();
  } catch (error) {
    if (isError(error)) {
      toast.error(error.message);
    }
  }

  try {
    await currentUser.delete();
  } catch (error) {
    const code = getFirebaseErrorCode(error);
    if (code === 'auth/requires-recent-login') {
      await loginWithGoogle();
      await currentUser.delete();
    }
  }

  toast(
    "It's sad to see you go, but we respect your privacy. All your data has been deleted successfully. Hope to see you again soon!",
  );
};

export default deleteAccount;
