import firebase from 'gatsby-plugin-firebase';

import { JournalEntry } from '../../types/journalItems/JournalEntry';
import { JournalItemEnum } from '../../types/JournalItem';

const createJournalEntry = async (args: {
  uid: string;
  id: string;
  title?: string;
  content?: string;
  createdAt?: number;
}): Promise<void> => {
  const { uid, id, title, content, createdAt } = args;

  const newCreatedAt = createdAt ?? firebase.database.ServerValue.TIMESTAMP;
  const newTitle = title ?? '';
  const newContent = content ?? '';

  const journalEntry: JournalEntry = {
    id,
    user: uid,
    createdAt: newCreatedAt as number,
    updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
    title: newTitle,
    content: newContent,
    type: JournalItemEnum.entry,
    isSubmitted: false,
  };

  firebase.database().ref(`journalentries/${id}`).set(journalEntry);
};

export default createJournalEntry;
