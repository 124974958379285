import { Resume } from '../types/Resume';
import defaultLayouts from './defaultLayouts';

const demoState: Resume = {
  awards: {
    heading: 'Awards',
    items: [
      {
        awarder: 'Winner',
        date: '',
        id: '58d7c5ec-6abc-41dc-9b0f-408e6b5c154e',
        summary: '',
        title: 'Marketing Case Competition',
      },
    ],
    visible: true,
  },
  certifications: {
    heading: 'Certifications',
    items: [
      {
        date: '',
        id: 'ef9c0335-92ca-4291-b1b3-c03597ce11e5',
        issuer: ' ',
        summary: '',
        title: 'Google Analytics Individual Certification',
      },
      {
        date: '',
        id: '02f000f5-07a2-4c85-8f22-6e1a745a1e5f',
        issuer: 'Scrum Alliance',
        summary: '',
        title: 'Certified Scrum Product Owner',
      },
    ],
    visible: true,
  },
  createdAt: 1601733060190,
  education: {
    heading: 'Education',
    items: [
      {
        degree: 'BSc',
        endDate: '2004-01-01',
        field: 'Marketing',
        gpa: '4.6/5',
        id: 'fffe574a-adde-4df6-aee7-8509c962c565',
        institution: 'XXX University of Singapore',
        startDate: '2001-01-01',
        summary: 'Awarded XXXX\n- President of XXX Club\n- Scholar of XXX',
      },
    ],
    visible: true,
  },
  hobbies: {
    heading: 'Interests',
    items: [
      {
        id: '46af6ab5-3c2c-45b5-8b86-b186e11e8b2d',
        name: 'Running (3 Time Marathoner)',
      },
      {
        id: 'cb981047-646f-40eb-bb1a-7f5a5c921d21',
        name: 'Piano (ABRSM Grade 8)',
      },
    ],
    visible: true,
  },
  id: 'johndoe',
  languages: {
    heading: 'Languages',
    items: [
      {
        fluency: 'Native',
        id: '805ec0a4-0d14-4c5e-bdfb-aa8aa41c53e4',
        name: 'English',
      },
      {
        fluency: 'Native',
        id: '71cf3925-906f-491e-8572-3200ffe17d0a',
        name: 'Malay',
      },
    ],
    visible: true,
  },
  metadata: {
    colors: {
      background: '#FFFFFF',
      primary: '#5875DB',
      text: '#444444',
    },
    font: 'Nunito',
    language: 'en',
    layout: defaultLayouts,
    template: 'zetybanking',
    margins: {
      lineHeight: '1.3',
      fontSize: '9',
      lrMargins: '15',
      tbMargins: '15',
    },
  },
  name: 'John Doe',
  summary: {
    body:
      '(Adjective),(Adjective),(Adjective) marketing profession with expertise in xxx. Possess strong skillset in XXX.',
    heading: 'Summary',
    visible: true,
  },
  preview:
    'https://images.unsplash.com/photo-1599498448014-81d90414c50a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=400',
  profile: {
    firstName: 'John',
    heading: 'Profile',
    lastName: 'Doe',
    photograph: '',
    subtitle: 'Digital Marketing Manager',
  },
  volunteer: {
    heading: 'Volunteer',
    items: [
      {
        startDate: '01/07/2020',
        endDate: '01/10/2020',
        id: 'a087da10-4d1a-40a4-b4c4-6f5e3d4ae794',
        link: '',
        summary: 'Organised and planned XX ',
        title: 'XXX Non Profit Organisation',
      },
    ],
    visible: true,
  },
  projects: {
    heading: 'Projects',
    items: [
      {
        startDate: '01/07/2020',
        endDate: '01/10/2020',
        id: 'c087da10-4d1a-40a4-b4c4-6f5e3d4ae794',
        website: 'https://github.com/johndoe/Reactive-Resume',
        summary:
          'Reactive Resume, a free and open-source resume builder that works for you.',
        title: 'Reactive Resume',
      },
    ],
    visible: true,
  },
  public: true,
  skills: {
    heading: 'Skills',
    items: [
      {
        id: 'dc52df4d-32fa-4c4c-bc01-95c08b2e59d5',
        level: ' ',
        name: 'UI/UX',
      },
      {
        id: 'e4bbd043-05ab-4c18-8bb8-5e0f8e9e984c',
        level: ' ',
        name: 'Digital Marketing',
      },
      {
        id: 'd7571434-7a56-44ba-b79e-db194342f87e',
        level: ' ',
        name: 'Press Relations',
      },
      {
        id: '3a4431bc-4ef4-4809-8d14-84ed3500ac76',
        level: ' ',
        name: 'Search Engine Optimisation',
      },
    ],
    visible: true,
  },
  contact: {
    heading: 'Contact',
    email: 'johndoe@gmail.com',
    phone: '+65 9123 4567',
    address: {
      city: '',
      line1: 'Singaporean',
      line2: '',
      pincode: '',
    },
    website: 'https://johndoe.com.sg',
    linkedin: 'https://linkedin.com/johndoe',
  },
  updatedAt: 1601736107366,
  user: 'RMn5OjMF2ITclwZUDTKZRcCqvNU2',
  work: {
    heading: 'Work Experience',
    items: [
      {
        company: 'ACME Corportation, Singapore',
        endDate: '',
        id: 'f0c06c24-d7fb-48c5-94f4-e189bc948496',
        position: 'Senior Marketing Manager',
        startDate: '2011-01-01',
        summary:
          "- Managed and coached a team of 12 marketing professionals based across different countries in Asia Pacific, taking XXX company's digital properties including website, blog and social media accounts\n- Spearheaded deployment of Salesforces for project management, resulting in cost savings of over $100,000 annually and increasing overall workflow efficiency.\n- Implementation of Google Analytics on company website and implement strategies to bring website to first page on Google Search results for relevant brand keywords.",
        website: '',
      },
      {
        company: 'Company Name, Country',
        endDate: '2011-01-01',
        id: '079e14f2-6b39-4710-a7c4-c8d9006f95e0',
        position: 'Position / Dept (Group)',
        startDate: '2010-01-01',
        summary:
          '- Description of work and key highlights\n- Description of work and key highlights\n- Description of work and key highlights',
        website: '',
      },
      {
        company: 'Company Name, Country',
        endDate: '2011-01-01',
        id: '81cb25bd-b1ac-4bc7-85ae-3a0a2dafd4f1',
        position: 'Position Dept',
        startDate: '2010-01-01',
        summary:
          '- Description of work and key highlights\n- Description of work and key highlights\n- Description of work and key highlights',
        website: '',
      },
    ],
    visible: true,
  },
};

export default demoState;
