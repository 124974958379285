import firebase from 'gatsby-plugin-firebase';
import { Resume } from '../../types/Resume';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const updateResume = async (resume: Resume, versionId: string) => {
  const updatedAt = firebase.database.ServerValue.TIMESTAMP;
  const resumeToAdd: Resume = {
    ...resume,
    versionId,
    updatedAt: updatedAt as number,
  };
  const MAX_NUM_VERSIONS = 5;
  try {
    // Get db object
    const snapshot = await firebase
      .database()
      .ref(`resumes/${resume.id}`)
      .once('value');
    const resumeObj: ResumeDatabaseObject = snapshot.val();
    // Update resume with current version
    let updatedVersions = resumeObj.versions;
    const versionCount: number = Object.keys(resumeObj.versions).length;
    if (versionCount >= MAX_NUM_VERSIONS) {
      // Find version id of oldest version
      let minTimestamp: number = Number.MAX_VALUE;
      let oldestVersionId = '';
      const allVersions: ResumeDatabaseObject['versions'] = resumeObj.versions;
      for (const versionNum in allVersions) {
        if (Object.prototype.hasOwnProperty.call(allVersions, versionNum)) {
          const currTimestamp: number = allVersions[versionNum].updatedAt
            ? allVersions[versionNum].updatedAt
            : allVersions[versionNum].createdAt;
          if (currTimestamp < minTimestamp) {
            minTimestamp = currTimestamp;
            oldestVersionId = versionNum;
          }
        }
      }
      // Remove oldest resume version
      updatedVersions = Object.entries(allVersions).reduce(
        (updated, current) => {
          const versionNum: string = current[0];
          const version: Resume = current[1];
          if (versionNum !== oldestVersionId) {
            updated[versionNum] = version;
          }
          return updated;
        },
        {} as ResumeDatabaseObject['versions'],
      );
    }
    updatedVersions[versionId] = resumeToAdd;
    await firebase
      .database()
      .ref(`resumes/${resume.id}`)
      .update({
        ...resumeObj,
        latestVersion: versionId,
        versions: updatedVersions,
      });
  } catch (error) {
    return null;
  }
};

export default updateResume;
