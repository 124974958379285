import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_PIECHART_STATE } from '../types/pieChartState';
import {
  ActivityIdPayload,
  IsUpdatingPayload,
  PieChartActivitiesPayload,
  PieChartPayload,
  ReflectionPayload,
} from '../types/payloads';
import { RootState } from '../store';

export const PIECHART_SLICE_KEY = 'pieChart';

export const pieChartSlice = createSlice({
  name: PIECHART_SLICE_KEY,
  initialState: INITIAL_PIECHART_STATE,
  reducers: {
    setIsUpdating: (state, action: PayloadAction<IsUpdatingPayload>) => {
      state.isUpdating = action.payload.isUpdating;
    },
    setPieChart: (state, action: PayloadAction<PieChartPayload>) => {
      state.pieChart = action.payload.pieChart;
    },
  },
});

export const {
  setIsUpdating: setPieChartIsUpdating,
  setPieChart,
} = pieChartSlice.actions;

export const pieChartCreateNewItem = createAction<ActivityIdPayload>(
  'pieChartCreateNewItem',
);
export const pieChartLoadExistingItem = createAction<ActivityIdPayload>(
  'pieChartLoadExistingItem',
);

export const pieChartUpdateCurrentActivities = createAction<
  PieChartActivitiesPayload
>('pieChartUpdateCurrentActivities');

export const pieChartUpdateIdealActivities = createAction<
  PieChartActivitiesPayload
>('pieChartUpdateIdealActivities');

export const pieChartUpdateReflection = createAction<ReflectionPayload>(
  'pieChartUpdateReflection',
);
export const pieChartSubmit = createAction('pieChartSubmit');

export const selectPieChartState = (state: RootState) =>
  state[PIECHART_SLICE_KEY];
export const selectPieChartIsUpdating = (state: RootState) =>
  selectPieChartState(state)?.isUpdating;
export const selectPieChart = (state: RootState) =>
  selectPieChartState(state)?.pieChart;

export default pieChartSlice.reducer;
