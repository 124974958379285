import { createSelector } from 'reselect';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_RESUME_STATE } from '../types/resumeState';

import {
  IsUpdatingPayload,
  JsonResumePayload,
  LanguagePayload,
  ResumeIdPayload,
  ResumeIsNewVersionPayload,
  ResumeItemPayload,
  ResumePayload,
  ResumeVersionIdPayload,
  UpdatedAtPayload,
  ResumeIsPublicPayload,
  NamePayload,
  UserPayload,
} from '../types/payloads';
import { RootState } from '../store';
import getResumeSectionFromPath from '../../utils/getResumeSectionFromPath';

export const RESUME_SLICE_KEY = 'resume';

export const resumeSlice = createSlice({
  name: RESUME_SLICE_KEY,
  initialState: INITIAL_RESUME_STATE,
  reducers: {
    setIsUpdating: (state, action: PayloadAction<IsUpdatingPayload>) => {
      state.isUpdating = action.payload.isUpdating;
    },
    setResume: (state, action: PayloadAction<ResumePayload>) => {
      state.currentResumeVersion = action.payload.resume;
    },
    setPreviousResume: (state, action: PayloadAction<ResumePayload>) => {
      state.previousResumeVersion = action.payload.resume;
    },
    setResumeId: (state, action: PayloadAction<ResumeIdPayload>) => {
      state.resumeId = action.payload.resumeId;
    },
    setVersionId: (state, action: PayloadAction<ResumeVersionIdPayload>) => {
      state.versionId = action.payload.versionId;
    },
    setIsNewVersion: (
      state,
      action: PayloadAction<ResumeIsNewVersionPayload>,
    ) => {
      state.isNewVersion = action.payload.isNewVersion;
    },
    setUpdatedAt: (state, action: PayloadAction<UpdatedAtPayload>) => {
      state.updatedAt = action.payload.updatedAt;
    },
    setPreviewResume: (state, action: PayloadAction<ResumePayload>) => {
      state.previewResume = action.payload.resume;
    },
    setIsPublic: (state, action: PayloadAction<ResumeIsPublicPayload>) => {
      state.isPublic = action.payload.isPublic;
    },
  },
});

export const {
  setIsUpdating: setResumeIsUpdating,
  setResume,
  setPreviousResume,
  setVersionId,
  setResumeId,
  setUpdatedAt,
  setPreviewResume,
  setIsPublic,
} = resumeSlice.actions;

export const resumeOnCreate = createAction<NamePayload & UserPayload>(
  'resumeOnCreate',
);
export const resumeCreated = createAction<
  NamePayload & UserPayload & ResumeIdPayload
>('resumeCreated');
export const resumeAddItem = createAction<ResumeItemPayload>('resumeAddItem');
export const resumeEditItem = createAction<ResumeItemPayload>('resumeEditItem');
export const resumeDeleteItem = createAction<ResumeItemPayload>(
  'resumeDeleteItem',
);
export const resumeMoveItemUp = createAction<ResumeItemPayload>(
  'resumeMoveItemUp',
);
export const resumeMoveItemDown = createAction<ResumeItemPayload>(
  'resumeMoveItemDown',
);
export const resumeChangeLanguage = createAction<LanguagePayload>(
  'resumeChangeLanguage',
);
export const resumeResetLayout = createAction('resumeResetLayout');
export const resumeOnInput = createAction<ResumeItemPayload>('resumeOnInput');
export const resumeOnImport = createAction<ResumePayload>('resumeOnImport');
export const resumeOnImportJson = createAction<JsonResumePayload>(
  'resumeOnImportJson',
);
export const resumeOnOpen = createAction<ResumeIdPayload>('resumeOnOpen');
export const resumeResetData = createAction('resumeResetData');
export const resumeLoadDemoData = createAction('resumeLoadDemoData');
export const resumePreviewVersion = createAction<
  ResumeIdPayload & ResumeVersionIdPayload
>('resumePreviewVersion');
export const resumeSetIsPublic = createAction<
  ResumeIdPayload & ResumeIsPublicPayload
>('resumeSetIsPublic');

export const resumeUpdatedItem = createAction<ResumeItemPayload>(
  'resumeUpdatedItem',
);

// Other code such as selectors can use the imported `RootState` type
export const selectResumeState = (state: RootState) => state[RESUME_SLICE_KEY];
export const selectResumeIsUpdating = (state: RootState) =>
  selectResumeState(state)?.isUpdating;
export const selectResume = (state: RootState) =>
  selectResumeState(state)?.currentResumeVersion;
export const selectResumePreviousVersion = (state: RootState) =>
  selectResumeState(state)?.previousResumeVersion;
export const selectResumeId = (state: RootState) =>
  selectResumeState(state)?.resumeId;
export const selectVersionId = (state: RootState) =>
  selectResumeState(state)?.versionId;
export const selectResumeIsNewVersion = (state: RootState) =>
  selectResumeState(state)?.isNewVersion;
export const selectResumeUpdatedAt = (state: RootState) =>
  selectResumeState(state)?.updatedAt;
export const selectResumePreview = (state: RootState) =>
  selectResumeState(state)?.previewResume;
export const selectResumeIsPublic = (state: RootState) =>
  selectResumeState(state)?.isPublic;
export default resumeSlice.reducer;

export const selectResumeItem = (
  path: string,
  fallback?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) =>
  createSelector([selectResume], (resume) => {
    if (resume == null) {
      return;
    }
    return getResumeSectionFromPath(resume, path, fallback);
  });
