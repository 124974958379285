import { PieChart } from '../../types/journalItems/PieChart';

export type PieChartState = {
  isUpdating: boolean;
  pieChart: PieChart | null;
};

export const INITIAL_PIECHART_STATE = {
  isUpdating: false,
  pieChart: null,
} as PieChartState;
