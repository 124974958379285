import firebase from 'gatsby-plugin-firebase';

import { getUnsplashPhoto } from '../../utils';
import uuid from '../../utils/uuid';
import initialState from '../../data/initialState';
import { RegisteredUser, User } from '../../types/User';
import { Resume } from '../../types/Resume';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const createResume = async (args: {
  name: string;
  user: User;
  resumeId?: string;
}): Promise<null> => {
  const { name, user, resumeId } = args;

  const id = resumeId ?? uuid();
  const versionId = uuid();
  const preview = await getUnsplashPhoto();
  const createdAt = firebase.database.ServerValue.TIMESTAMP;

  let firstName;
  let lastName;

  if (user && !user.isAnonymous) {
    const registeredUser = user as RegisteredUser;
    [firstName, lastName] = registeredUser.displayName.split(' ');
  }

  const resume: Resume = {
    ...initialState,
    id,
    versionId,
    name,
    user: user.uid,
    preview,
    profile: {
      ...initialState.profile,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    },
    createdAt: createdAt as number,
    updatedAt: createdAt as number,
  };

  const initialNewResume: ResumeDatabaseObject = {
    user: user.uid,
    id,
    name,
    public: false,
    preview,
    latestVersion: versionId,
    versions: { [versionId]: resume },
  };

  await firebase.database().ref(`resumes/${id}`).set(initialNewResume);
};

export default createResume;
