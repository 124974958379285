import { User } from '../../types/User';

export type UserState = {
  isLoading: boolean;
  user?: User;
  error?: firebase.default.auth.Error;
};

export const INITIAL_USER_STATE = {
  isLoading: false,
  user: undefined,
  error: undefined,
} as UserState;
