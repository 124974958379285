import {
  call,
  put,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';

import {
  selectSCurve,
  setSCurveIsUpdating,
  setSCurve,
  sCurveCreateNewItem,
  sCurveLoadExistingItem,
  sCurveUpdateGoal,
  sCurveUpdateStage,
  sCurveUpdateReflection,
  sCurveSubmit,
} from '../slices/sCurveSlice';
import { selectUser } from '../slices/userSlice';

import { SCurve } from '../../types/journalItems/SCurve';
import { JournalItemEnum } from '../../types/JournalItem';
import { User } from '../../types/User';
import {
  ActivityIdPayload,
  Payload,
  ReflectionPayload,
  SCurveGoalPayload,
  SCurveStagePayload,
} from '../types/payloads';

import createSCurve from '../../api/journal/sCurve/createSCurve';
import getSCurve from '../../api/journal/sCurve/getSCurve';
import updateSCurve from '../../api/journal/sCurve/updateSCurve';

const DEBOUNCE_WAIT_TIME = 500;

function* updateSCurveDebounced() {
  const user: User | null = yield select(selectUser);
  if (user == null) {
    return;
  }
  yield delay(DEBOUNCE_WAIT_TIME);

  const currentSCurve: SCurve | null = yield select(selectSCurve);

  if (currentSCurve == null) return;

  yield put(setSCurveIsUpdating({ isUpdating: true }));
  yield call(updateSCurve, currentSCurve);
  yield put(setSCurveIsUpdating({ isUpdating: false }));
}

function* createSCurveItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;
  const user: User | null = yield select(selectUser);

  const newSCurve: SCurve = {
    id: activityId,
    user: user?.uid ?? '',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    title: 'sCurve',
    type: JournalItemEnum.activity,
    isSubmitted: false,
    goal: '',
    stage: 0,
    reflection: '',
  };

  if (user != null) {
    const { user: uid, id, goal, stage, createdAt } = newSCurve;
    yield call(createSCurve, {
      uid,
      id,
      goal,
      stage,
      createdAt,
    });
  }

  yield put(
    setSCurve({
      sCurve: newSCurve,
    }),
  );
}

function* loadSCurveItem({ payload }: Payload<ActivityIdPayload>) {
  const { activityId } = payload;
  const loadedSCurve: SCurve | null = yield call(getSCurve, activityId);
  if (loadedSCurve != null) {
    yield put(
      setSCurve({
        sCurve: loadedSCurve,
      }),
    );
  }
}

function* updateSCurveGoal({ payload }: Payload<SCurveGoalPayload>) {
  const currentSCurve: SCurve | null = yield select(selectSCurve);
  if (currentSCurve == null) return;
  yield put(
    setSCurve({
      sCurve: {
        ...currentSCurve,
        ...payload,
      },
    }),
  );
}

function* updateSCurveStage({ payload }: Payload<SCurveStagePayload>) {
  const currentSCurve: SCurve | null = yield select(selectSCurve);
  if (currentSCurve == null) return;
  yield put(
    setSCurve({
      sCurve: {
        ...currentSCurve,
        ...payload,
      },
    }),
  );
}

function* updateSCurveReflection({ payload }: Payload<ReflectionPayload>) {
  const currentSCurve: SCurve | null = yield select(selectSCurve);
  if (currentSCurve == null) return;
  yield put(
    setSCurve({
      sCurve: {
        ...currentSCurve,
        ...payload,
      },
    }),
  );
}

function* submitSCurve() {
  const currentSCurve: SCurve | null = yield select(selectSCurve);
  if (currentSCurve == null) return;
  yield put(
    setSCurve({
      sCurve: {
        ...currentSCurve,
        isSubmitted: true,
      },
    }),
  );
}

function* sCurveSaga() {
  yield takeLatest(setSCurve, updateSCurveDebounced);
  yield takeEvery(sCurveCreateNewItem, createSCurveItem);
  yield takeEvery(sCurveLoadExistingItem, loadSCurveItem);
  yield takeEvery(sCurveUpdateGoal, updateSCurveGoal);
  yield takeEvery(sCurveUpdateStage, updateSCurveStage);
  yield takeEvery(sCurveUpdateReflection, updateSCurveReflection);
  yield takeEvery(sCurveSubmit, submitSCurve);
}

export default sCurveSaga;
