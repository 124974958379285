import firebase from 'gatsby-plugin-firebase';

import { SCurve } from '../../../types/journalItems/SCurve';
import { JournalItemEnum } from '../../../types/JournalItem';
import { User } from '../../../types/User';

const createSCurve = async (args: {
  uid: User['uid'];
  id: string;
  createdAt?: number;
  goal?: string;
  stage?: number;
  reflection?: string;
}): Promise<void> => {
  const { uid, id, createdAt, goal, stage, reflection } = args;

  const newCreatedAt = createdAt ?? firebase.database.ServerValue.TIMESTAMP;
  const newGoal = goal ?? '';
  const newStage = stage ?? 0;
  const newReflection = reflection ?? '';
  const sCurve: SCurve = {
    id,
    user: uid,
    createdAt: newCreatedAt as number,
    updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
    goal: newGoal,
    stage: newStage,
    reflection: newReflection,
    type: JournalItemEnum.activity,
    title: 'sCurve',
    isSubmitted: false,
  };

  firebase.database().ref(`scurve/${id}`).set(sCurve);
};

export default createSCurve;
