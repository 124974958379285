import {
  call,
  put,
  takeEvery,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects';
import { clone, findIndex, get, setWith, set, has } from 'lodash';
import arrayMove from 'array-move';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import updateResume from '../../api/resume/updateResume';
import initialState from '../../data/initialState';
import demoState from '../../data/demoState';
import defaultLayouts from '../../data/defaultLayouts';
import getResume from '../../api/resume/getResume';
import updateResumeSharing from '../../api/resume/updateResumeSharing';

import {
  setResumeIsUpdating,
  setUpdatedAt,
  setResume,
  setPreviousResume,
  setPreviewResume,
  setVersionId,
  setIsPublic,
  selectResume,
  selectVersionId,
  resumeAddItem,
  resumeEditItem,
  resumeDeleteItem,
  resumeMoveItemUp,
  resumeMoveItemDown,
  resumeChangeLanguage,
  resumeResetLayout,
  resumeOnInput,
  resumeOnImport,
  resumeResetData,
  resumeLoadDemoData,
  resumeOnImportJson,
  resumePreviewVersion,
  resumeOnOpen,
  setResumeId,
  resumeSetIsPublic,
  resumeUpdatedItem,
  resumeOnCreate,
  resumeCreated,
} from '../slices/resumeSlice';

import { Resume } from '../../types/Resume';
import {
  JsonResumePayload,
  LanguagePayload,
  NamePayload,
  Payload,
  ResumeIdPayload,
  ResumeIsPublicPayload,
  ResumeItemPayload,
  ResumePayload,
  ResumeVersionIdPayload,
  UserPayload,
} from '../types/payloads';
import { Template } from '../../types/Template';
import uuid from '../../utils/uuid';
import getResumeSharing from '../../api/resume/getResumeSharing';
import getLatestResume from '../../api/resume/getLatestResume';
import createResume from '../../api/resume/createResume';

const DEBOUNCE_WAIT_TIME = 4000;

function* updateResumeDebounced({ payload }: Payload<ResumePayload>) {
  yield delay(DEBOUNCE_WAIT_TIME);

  const { resume } = payload;
  yield put(setResumeIsUpdating({ isUpdating: true }));
  yield put(setUpdatedAt({ updatedAt: Date.now() }));
  const version: string = yield select(selectVersionId);
  yield call(updateResume, resume, version);
  yield put(setResumeIsUpdating({ isUpdating: false }));
}

function* createResumeSaga({ payload }: Payload<NamePayload & UserPayload>) {
  const { name, user } = payload;
  const resumeId = uuid();
  yield call(createResume, { name, user, resumeId });
  yield put(resumeCreated({ name, user, resumeId }));
}

function* addResumeItem({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const items = get(currentResume, payload.path, []);
  const updatedResume = setWith(
    clone(currentResume),
    payload.path,
    [...items, payload.value],
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
  yield put(resumeUpdatedItem(payload));
}

function* editResumeItem({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const items = get(currentResume, payload.path);
  const index = findIndex(items, ['id', payload.value.id]);
  const updatedResume = setWith(
    clone(currentResume),
    `${payload.path}[${index}]`,
    payload.value,
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
  yield put(resumeUpdatedItem(payload));
}

function* onResumeOpen({ payload }: Payload<ResumeIdPayload>) {
  const { resumeId } = payload;

  const latestResume: Resume = yield call(getLatestResume, resumeId);
  const isPublic: boolean = yield call(getResumeSharing, resumeId);

  yield put(setIsPublic({ isPublic }));
  const newVersion: string = uuid();
  yield put(setVersionId({ versionId: newVersion }));
  yield put(setResumeId({ resumeId }));
  const newResumeVersion: Resume = {
    ...latestResume,
    versionId: newVersion,
  };
  yield put(setResume({ resume: newResumeVersion }));
  yield put(setPreviousResume({ resume: latestResume }));
}

function* deleteResumeItem({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const items = get(currentResume, payload.path);
  const index = findIndex(items, ['id', payload.value.id]);
  const newItems = [...items];
  newItems.splice(index, 1);
  const updatedResume = setWith(
    clone(currentResume),
    payload.path,
    newItems,
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
  yield put(resumeUpdatedItem(payload));
}

function* moveResumeItemUp({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const currentItems = get(currentResume, payload.path);
  const index = findIndex(currentItems, ['id', payload.value.id]);
  const updatedItems = arrayMove(currentItems, index, index - 1);
  const updatedResume = setWith(
    clone(currentResume),
    payload.path,
    updatedItems,
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
}

function* moveResumeItemDown({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const currentItems = get(currentResume, payload.path);
  const index = findIndex(currentItems, ['id', payload.value.id]);
  const updatedItems = arrayMove(currentItems, index, index + 1);
  const updatedResume = setWith(
    clone(currentResume),
    payload.path,
    updatedItems,
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
}

function* changeResumeLanguage({ payload }: Payload<LanguagePayload>) {
  const currentResume: Resume = yield select(selectResume);

  const updatedResume = set(clone(currentResume), 'metadata.language', payload);
  const items = get(
    i18next.getDataByLanguage(payload.lang),
    'translation.builder.sections',
  );
  Object.keys(items).forEach((key) => {
    has(updatedResume, `${key}.heading`) &&
      set(updatedResume, `${key}.heading`, items[key]);
  });
  yield put(setResume({ resume: updatedResume }));
}

function* resetResumeLayout() {
  const currentResume: Resume = yield select(selectResume);

  const temp: Template = get(currentResume, 'metadata.template');
  const updatedResume = setWith(
    clone(currentResume),
    `metadata.layout.${temp}`,
    defaultLayouts[temp],
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
}

function* onResumeInput({ payload }: Payload<ResumeItemPayload>) {
  const currentResume: Resume = yield select(selectResume);

  const updatedResume = setWith(
    clone(currentResume),
    payload.path,
    payload.value,
    clone,
  );
  yield put(setResume({ resume: updatedResume }));
}

function* onResumeImport({ payload }: Payload<ResumePayload>) {
  const currentResume: Resume = yield select(selectResume);

  const updatedResume = payload.resume;
  updatedResume.id = currentResume.id;
  updatedResume.user = currentResume.user;
  updatedResume.name = currentResume.name;
  updatedResume.createdAt = currentResume.createdAt;
  yield put(setResume({ resume: updatedResume }));
}

function* onResumeJsonImport({ payload }: Payload<JsonResumePayload>) {
  const currentResume: Resume = yield select(selectResume);
  const jsonResume = payload.resume;

  const temp = clone(currentResume);
  const updatedResume: Resume = {
    ...initialState,
    id: temp.id,
    versionId: temp.versionId,
    user: temp.user,
    name: temp.name,
    createdAt: temp.createdAt,
    preview: temp.preview,
  };

  updatedResume.profile = {
    heading: 'Profile',
    firstName: jsonResume.basics.name,
    lastName: '',
    photograph: jsonResume.basics.picture,
    subtitle: jsonResume.basics.label,
  };

  updatedResume.contact = {
    heading: 'Contact',
    address: {
      city: jsonResume.basics.location.city,
      line1: jsonResume.basics.location.address,
      line2: jsonResume.basics.location.region,
      pincode: jsonResume.basics.location.postalCode,
    },
    email: jsonResume.basics.email,
    website: jsonResume.basics.website,
    phone: jsonResume.basics.phone,
    linkedin: jsonResume.basics.linkedIn,
  };

  updatedResume.summary.body = jsonResume.basics.summary;
  updatedResume.work.items = jsonResume.work.map((x) => ({
    id: uuidv4(),
    company: x.company,
    endDate: x.endDate,
    position: x.position,
    startDate: x.startDate,
    summary: x.summary,
    website: x.website,
  }));
  updatedResume.education.items =
    jsonResume.education &&
    jsonResume.education.map((x) => ({
      id: uuidv4(),
      degree: x.studyType,
      endDate: x.endDate,
      field: x.area,
      gpa: x.gpa,
      institution: x.institution,
      startDate: x.startDate,
      summary: x.courses.join('\n'),
    }));
  updatedResume.awards.items = jsonResume.awards.map((x) => ({
    id: uuidv4(),
    awarder: x.awarder,
    date: x.date,
    summary: x.summary,
    title: x.title,
  }));
  updatedResume.skills.items = jsonResume.skills.map((x) => ({
    id: uuidv4(),
    level: 'Fundamental Awareness',
    name: x.name,
  }));
  updatedResume.hobbies.items = jsonResume.interests.map((x) => ({
    id: uuidv4(),
    name: x.name,
  }));
  updatedResume.languages.items = jsonResume.languages.map((x) => ({
    id: uuidv4(),
    name: x.language,
    fluency: x.fluency,
  }));

  yield put(setResume({ resume: updatedResume }));
}

function* resetResumeData() {
  const currentResume: Resume = yield select(selectResume);
  const { id, versionId, user, name, createdAt, preview } = currentResume;
  const updatedResume: Resume = {
    ...initialState,
    id,
    versionId,
    user,
    name,
    createdAt,
    preview,
  };

  yield put(setResume({ resume: updatedResume }));
}

function* loadDemoData() {
  const currentResume: Resume = yield select(selectResume);

  const { id, user, name, createdAt, versionId, metadata } = currentResume;
  const updatedResume: Resume = {
    ...demoState,
    id,
    user,
    name,
    createdAt,
    versionId,
    metadata,
  };

  const updatedLayoutResume = setWith(
    clone(updatedResume),
    `metadata.layout`,
    demoState.metadata.layout,
    clone,
  );
  yield put(setResume({ resume: updatedLayoutResume }));
}

function* onResumePreview({
  payload,
}: Payload<ResumeIdPayload & ResumeVersionIdPayload>) {
  const { resumeId, versionId } = payload;
  const previewResume: Resume = yield call(getResume, resumeId, versionId);
  yield put(setPreviewResume({ resume: previewResume }));
}

function* updateResumePrivacy({
  payload,
}: Payload<ResumeIdPayload & ResumeIsPublicPayload>) {
  const { resumeId, isPublic } = payload;
  yield call(updateResumeSharing, resumeId, isPublic);
  yield put(setIsPublic({ isPublic }));
}

function* resumeSaga() {
  yield takeLatest(setResume, updateResumeDebounced);
  yield takeEvery(resumeAddItem, addResumeItem);
  yield takeEvery(resumeEditItem, editResumeItem);
  yield takeEvery(resumeDeleteItem, deleteResumeItem);
  yield takeEvery(resumeMoveItemUp, moveResumeItemUp);
  yield takeEvery(resumeMoveItemDown, moveResumeItemDown);
  yield takeEvery(resumeChangeLanguage, changeResumeLanguage);
  yield takeEvery(resumeResetLayout, resetResumeLayout);
  yield takeEvery(resumeOnInput, onResumeInput);
  yield takeEvery(resumeOnImport, onResumeImport);
  yield takeEvery(resumeOnImportJson, onResumeJsonImport);
  yield takeEvery(resumeResetData, resetResumeData);
  yield takeEvery(resumeLoadDemoData, loadDemoData);
  yield takeEvery(resumeOnCreate, createResumeSaga);
  yield takeEvery(resumeOnOpen, onResumeOpen);
  yield takeEvery(resumePreviewVersion, onResumePreview);
  yield takeEvery(resumeSetIsPublic, updateResumePrivacy);
}

export default resumeSaga;
