import React from 'react';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import './src/styles/forms.css';
import './src/styles/global.css';
import './src/styles/shadows.css';
import './src/styles/tailwind.css';
import './src/styles/toastify.css';
import 'animate.css';

import { Provider } from 'react-redux';
import { store } from './src/redux/store';

import { ModalProvider } from './src/contexts/ModalContext';
import { SettingsProvider } from './src/contexts/SettingsContext';
import { StorageProvider } from './src/contexts/StorageContext';
import { AuthProvider } from './src/contexts/AuthContext';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';

import './src/i18n';
import './src/utils/dayjs';

const theme = createMuiTheme({
  typography: {
    fontWeightRegular: 500,
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
});

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <AuthProvider>
      <SettingsProvider>
        <MuiThemeProvider theme={theme}>
          <ModalProvider>
            <StorageProvider>{element}</StorageProvider>
          </ModalProvider>
        </MuiThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  </Provider>
);
