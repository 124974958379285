import firebase from 'gatsby-plugin-firebase';
import { Resume } from '../../types/Resume';

const getResume = async (
  id: string,
  versionId: string,
): Promise<Resume | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`resumes/${id}/versions/${versionId}`)
      .once('value');
    const resume: Resume = snapshot.val();
    return resume;
  } catch (error) {
    return null;
  }
};

export default getResume;
