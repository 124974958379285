import { User } from '../../types/User';
import { Entry } from '../../types/Timeline';
import createTimelineEntry from './createTimelineEntry';

const createTimelineEntries = async (args: {
  userId: User['uid'];
  entries: Array<Entry>;
}): Promise<void> => {
  const { userId, entries } = args;

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    createTimelineEntry({ userId, entry });
  }
};

export default createTimelineEntries;
