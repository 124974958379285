import firebase from 'gatsby-plugin-firebase';

import {
  PieChart,
  PieChartActivity,
} from '../../../types/journalItems/PieChart';
import { JournalItemEnum } from '../../../types/JournalItem';
import { User } from '../../../types/User';

const createPieChart = async (args: {
  uid: User['uid'];
  id: string;
  currentActivities?: Array<PieChartActivity>;
  idealActivities?: Array<PieChartActivity>;
  reflection?: string;
  createdAt?: number;
}): Promise<void> => {
  const {
    uid,
    id,
    currentActivities,
    idealActivities,
    reflection,
    createdAt,
  } = args;

  const newCreatedAt = createdAt ?? firebase.database.ServerValue.TIMESTAMP;
  const newReflection = reflection ?? '';
  const newCurrentActivities =
    currentActivities ?? new Array<PieChartActivity>();
  const newIdealActivities = idealActivities ?? new Array<PieChartActivity>();

  const pieChart: PieChart = {
    id,
    user: uid,
    createdAt: newCreatedAt as number,
    updatedAt: firebase.database.ServerValue.TIMESTAMP as number,
    currentActivities: newCurrentActivities,
    idealActivities: newIdealActivities,
    reflection: newReflection,
    title: 'piechart',
    type: JournalItemEnum.activity,
    isSubmitted: false,
  };

  firebase.database().ref(`piechart/${id}`).set(pieChart);
};

export default createPieChart;
