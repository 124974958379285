import firebase from 'gatsby-plugin-firebase';
import { WPLHDashboard } from '../../../types/journalItems/WPLHDashboard';

const getWPLHDashboard = async (id: string): Promise<WPLHDashboard | null> => {
  try {
    const snapshot = await firebase.database().ref(`WPLH/${id}`).once('value');
    const wplhDashboard: WPLHDashboard = snapshot.val();
    return wplhDashboard;
  } catch (error) {
    return null;
  }
};

export default getWPLHDashboard;
