import { Resume } from '../../types/Resume';
import initialState from '../../data/initialState';

export type ResumeState = {
  isUpdating: boolean;
  currentResumeVersion: Resume;
  resumeId: string;
  versionId: string;
  isNewVersion: boolean;
  previousResumeVersion: Resume | null;
  updatedAt: number;
  previewResume: Resume | null;
  isPublic: boolean;
};

export const INITIAL_RESUME_STATE = {
  isUpdating: false,
  currentResumeVersion: initialState,
  resumeId: '',
  versionId: '',
  isNewVersion: false,
  previousResumeVersion: null,
  updatedAt: Date.now(),
  previewResume: null,
  isPublic: false,
} as ResumeState;
