import firebase from 'gatsby-plugin-firebase';

const deleteTimelineEntries = async (args: {
  userId: string;
  resourceId: string;
  versionId?: string;
}): Promise<void> => {
  const { userId, versionId, resourceId } = args;

  let entries = [];

  if (versionId == null) {
    const snapshot = await firebase
      .database()
      .ref(`timelines/${userId}`)
      .orderByChild('resourceId')
      .equalTo(resourceId)
      .once('value');

    entries = snapshot.val();
  } else {
    const snapshot = await firebase
      .database()
      .ref(`timelines/${userId}`)
      .orderByChild('versionId')
      .equalTo(versionId)
      .once('value');
    entries = snapshot.val();
  }

  if (entries == null) {
    return;
  }
  await Promise.all(
    Object.keys(entries).map((entryId: string) =>
      firebase.database().ref(`timelines/${userId}/${entryId}`).remove(),
    ),
  );
};

export default deleteTimelineEntries;
