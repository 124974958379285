import { JournalEntry } from '../../types/journalItems/JournalEntry';

export type JournalEntryState = {
  isUpdating: boolean;
  journalEntry: JournalEntry | null;
};

export const INITIAL_JOURNAL_ENTRY_STATE = {
  isUpdating: false,
  journalEntry: null,
} as JournalEntryState;
