import firebase from 'gatsby-plugin-firebase';
import { User } from '../../types/User';
import { Entry } from '../../types/Timeline';

const createTimelineEntry = async (args: {
  userId: User['uid'];
  entry: Entry;
}): Promise<void> => {
  const { userId, entry } = args;

  const timelineEntry = entry;
  firebase
    .database()
    .ref(`timelines/${userId}/${entry.entryId}`)
    .set(timelineEntry);
};

export default createTimelineEntry;
