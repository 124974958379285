import firebase from 'gatsby-plugin-firebase';
import { isError } from 'lodash';
import { toast } from 'react-toastify';

const loginWithGoogle = async (): Promise<firebase.auth.UserCredential | void> => {
  const provider = new firebase.auth.GoogleAuthProvider();

  try {
    return await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    if (isError(error)) {
      toast.error(error.message);
    }
  }
};

export default loginWithGoogle;
