import firebase from 'gatsby-plugin-firebase';
import { PieChart } from '../../../types/journalItems/PieChart';

const updatePieChart = async (pieChart: PieChart) => {
  await firebase
    .database()
    .ref(`piechart/${pieChart.id}`)
    .update({
      ...pieChart,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
};

export default updatePieChart;
