import { Entry, Timeline } from '../../types/Timeline';

export type ResumeUpdateEntries = {
  skills: Entry[];
  certifications: Entry[];
  education: Entry[];
  work: Entry[];
};

export type TimelineState = {
  timeline: Timeline;
  resumeUpdates: {
    shouldUpdate: boolean;
    entries: ResumeUpdateEntries | null;
  };
};

export const INITIAL_TIMELINE_STATE = {
  timeline: {},
  resumeUpdates: {
    shouldUpdate: false,
    entries: null,
  },
} as TimelineState;
