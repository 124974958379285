import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_JOURNAL_ENTRY_STATE } from '../types/journalEntryState';

import {
  IsUpdatingPayload,
  JournalDetailsPayload,
  JournalEntryPayload,
  JournalIdPayload,
} from '../types/payloads';
import { RootState } from '../store';

export const JOURNAL_ENTRY_SLICE_KEY = 'journalEntry';

export const journalEntrySlice = createSlice({
  name: JOURNAL_ENTRY_SLICE_KEY,
  initialState: INITIAL_JOURNAL_ENTRY_STATE,
  reducers: {
    setIsUpdating: (state, action: PayloadAction<IsUpdatingPayload>) => {
      state.isUpdating = action.payload.isUpdating;
    },
    setJournalEntry: (state, action: PayloadAction<JournalEntryPayload>) => {
      state.journalEntry = action.payload.journalEntry;
    },
  },
});

export const {
  setIsUpdating: setJournalEntryIsUpdating,
  setJournalEntry,
} = journalEntrySlice.actions;

export const journalEntryCreateNewItem = createAction<JournalIdPayload>(
  'journalEntryCreateNewItem',
);
export const journalEntryLoadExistingItem = createAction<JournalIdPayload>(
  'journalEntryLoadExistingItem',
);

export const journalEntryUpdateDetails = createAction<JournalDetailsPayload>(
  'journalEntryUpdateDetails',
);
export const journalEntrySubmit = createAction('journalEntrySubmit');

export const selectJournalEntryState = (state: RootState) =>
  state[JOURNAL_ENTRY_SLICE_KEY];
export const selectJournalEntryIsUpdating = (state: RootState) =>
  selectJournalEntryState(state)?.isUpdating;
export const selectJournalEntry = (state: RootState) =>
  selectJournalEntryState(state)?.journalEntry;

export default journalEntrySlice.reducer;
